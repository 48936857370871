import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function FundingChoices() {
  const location = useLocation();
  const prevLocationRef = useRef(location);

  useEffect(() => {
    const fromLocation = prevLocationRef.current;
    const toLocation = location;

    const fromFullPath = fromLocation.pathname + fromLocation.search + fromLocation.hash;
    const toFullPath = toLocation.pathname + toLocation.search + toLocation.hash;

    if (fromFullPath && toFullPath && fromFullPath !== toFullPath) {
      const lastReloadPath = sessionStorage.getItem('lastReloadPath');
      if (lastReloadPath === toFullPath) {
        sessionStorage.removeItem('lastReloadPath');
      } else {
        sessionStorage.setItem('lastReloadPath', toFullPath);
        window.location.href = toFullPath;
      }
    }
    console.log('reloaded')
    prevLocationRef.current = toLocation;
  }, [location]);

  return null;
}
